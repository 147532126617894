// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-text-area .ant-row,
.custom-text-input .ant-row {
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/_CustomTextInput.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;AACd","sourcesContent":[".custom-text-area .ant-row,\n.custom-text-input .ant-row {\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
