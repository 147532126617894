// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-box-heading {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
    padding: 16px 0 8px;
}

@media only screen and (max-width:600px) {
    .feature-box-heading {
        font-size: 20px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/FeaturesBox/featurebox.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".feature-box-heading {\n    color: var(--text-color);\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 120%;\n    /* 28.8px */\n    padding: 16px 0 8px;\n}\n\n@media only screen and (max-width:600px) {\n    .feature-box-heading {\n        font-size: 20px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
