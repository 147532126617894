import React, { useRef } from 'react'
import CTA from '../../Containers/Home/CTA/CTA'
import WhyProFlow from '../../Containers/Home/WhyProFlow/WhyProFlow'
import WhatWeDo from '../../Containers/Home/WhatWeDo/WhatWeDo'
import AboutUs from '../../Containers/Home/AboutUs/AboutUs'
import SearchExperience from '../../Containers/Home/SearchExperience/SearchExperience'
import MoreFeatures from '../../Containers/Home/MoreFeatures/MoreFeatures'
import Testimonials from '../../Containers/Home/Testimonials/Testimonials'
import ProFlowForm from '../../Containers/Home/ProFlowForm/ProFlowForm'
import Banner from '../../Containers/Home/HomeBanner/Banner/Banner'
import Imprint from '../Imprint/Imprint';
import Privacy from '../Privacy/Privacy';

const Home = () => {
  const ref = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)

  return (
    <>
      <div className="container" style={{ position: "relative" }}>
        <Banner />
        <CTA ref1={ref} />
        <WhyProFlow ref1={ref} ref2={ref2} />
        <WhatWeDo />
        <AboutUs />
        <SearchExperience />
        <MoreFeatures ref3={ref3} />
        <Testimonials />
        <ProFlowForm ref1={ref} ref2={ref2} ref3={ref3} />
      </div>
    </>
  )
}

export default Home