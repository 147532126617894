import React from 'react'
import './AboutCard.css'



const AboutCard = ({ key, position, title, image }) => {
  return (
    <>
      <div key={key} className="about-card">
        <img style={{ width: '100%' }} src={image} alt="Rudolf" />
        <div style={{ padding: '24px', textAlign: 'center' }} className="about-card-content">
          <p className="card-position">
            {position}
          </p>
          <h3 className="card-title">
            {title}
          </h3>
        </div>
      </div>
    </>
  )
}

export default AboutCard