// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .cta {
        display: block !important;
        padding: 24px 25px;
        text-align: center;
    }

    .cta p {
        font-size: 22px !important;
    }

    .cta .p-12 {
        font-size: 16px !important;
        line-height: 120% !important;
    }

    .cta .button-wrapper {
        justify-content: center !important;
        margin-top: 20px !important;
    }

    .cta .button-wrapper button {
        min-width: 160px !important;
        height: 45px !important;
        font-size: 14px !important;
    }

    /* .cta button {
        padding: 10px 16px !important;
        border-radius: 40px !important;
        font-size: 14px !important;
        margin-top: 16px;
    } */

    .cta p:nth-child(2) {
        margin-bottom: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/CTA/cta.css"],"names":[],"mappings":"AAAA;IACI;QACI,yBAAyB;QACzB,kBAAkB;QAClB,kBAAkB;IACtB;;IAEA;QACI,0BAA0B;IAC9B;;IAEA;QACI,0BAA0B;QAC1B,4BAA4B;IAChC;;IAEA;QACI,kCAAkC;QAClC,2BAA2B;IAC/B;;IAEA;QACI,2BAA2B;QAC3B,uBAAuB;QACvB,0BAA0B;IAC9B;;IAEA;;;;;OAKG;;IAEH;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .cta {\n        display: block !important;\n        padding: 24px 25px;\n        text-align: center;\n    }\n\n    .cta p {\n        font-size: 22px !important;\n    }\n\n    .cta .p-12 {\n        font-size: 16px !important;\n        line-height: 120% !important;\n    }\n\n    .cta .button-wrapper {\n        justify-content: center !important;\n        margin-top: 20px !important;\n    }\n\n    .cta .button-wrapper button {\n        min-width: 160px !important;\n        height: 45px !important;\n        font-size: 14px !important;\n    }\n\n    /* .cta button {\n        padding: 10px 16px !important;\n        border-radius: 40px !important;\n        font-size: 14px !important;\n        margin-top: 16px;\n    } */\n\n    .cta p:nth-child(2) {\n        margin-bottom: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
