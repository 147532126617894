import { Button } from 'antd'
import React from 'react'

const CustomButton = ({
    color = "#fff",
    background = '#2C4794',
    borderRadius = "40px",
    fontSize = "18px",
    fontWeight = 700,
    height = "38px",
    padding = "0 15px",
    minWidth = "100px",
    border = "none",
    title = "Button",
    type = "default",
    loading = false,
    disabled = false,
    onClick = () => null,
    styleObj,
    ...rest
}) => {
    return (
        <Button
            size='small'
            onClick={onClick}
            type={type}
            loading={loading}
            disabled={disabled}
            className='custom-button'
            style={{
                color: color,
                background: background,
                borderRadius: borderRadius,
                border: border,
                fontWeight: fontWeight,
                fontSize: fontSize,
                height: height,
                padding: padding,
                minWidth: minWidth,
                ...styleObj
            }}
            {...rest}
        >
            {title}
        </Button>
    )
}

export default CustomButton
