// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-card {
    background: #fff;
    min-height: 200px;
    min-width: 100%;
    padding: 0px;
    border-radius: 16px;
}

.testimonial-content {
    padding: 28px 100px;
}

.author-title {
    color: var(--primary-color, #2C4794);
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
}

.author-position {
    color: var(--text-color, #2D2D2D);
    font-size: 16px;
    opacity: 0.4;
    font-weight: 600;
    line-height: normal;
    padding-top: 4px;
}

.author-review {
    color: var(--text-color, #2D2D2D);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    padding: 16px 0 20px;
}

@media only screen and (max-width:600px) {
    .testimonial-card {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .testimonial-content {
        padding: 0;
    }
    .testimonial-profile {
        min-width: 100%;
        border-radius: 16px 16px 0 0 !important;
        padding:20px 0;
    }
    .testimonial-content {
        margin:20px;
    }
    .author-review  {
        font-size:16px;
        line-height: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/Testimonials/testimonials.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,uBAAuB;IAC3B;IACA;QACI,UAAU;IACd;IACA;QACI,eAAe;QACf,uCAAuC;QACvC,cAAc;IAClB;IACA;QACI,WAAW;IACf;IACA;QACI,cAAc;QACd,iBAAiB;IACrB;AACJ","sourcesContent":[".testimonial-card {\n    background: #fff;\n    min-height: 200px;\n    min-width: 100%;\n    padding: 0px;\n    border-radius: 16px;\n}\n\n.testimonial-content {\n    padding: 28px 100px;\n}\n\n.author-title {\n    color: var(--primary-color, #2C4794);\n    font-size: 24px;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.author-position {\n    color: var(--text-color, #2D2D2D);\n    font-size: 16px;\n    opacity: 0.4;\n    font-weight: 600;\n    line-height: normal;\n    padding-top: 4px;\n}\n\n.author-review {\n    color: var(--text-color, #2D2D2D);\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 30px;\n    padding: 16px 0 20px;\n}\n\n@media only screen and (max-width:600px) {\n    .testimonial-card {\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n    }\n    .testimonial-content {\n        padding: 0;\n    }\n    .testimonial-profile {\n        min-width: 100%;\n        border-radius: 16px 16px 0 0 !important;\n        padding:20px 0;\n    }\n    .testimonial-content {\n        margin:20px;\n    }\n    .author-review  {\n        font-size:16px;\n        line-height: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
