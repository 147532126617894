import React from 'react'
import { Row, Col } from 'antd'
import './Banner.css'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import BackgroundMainSvg from "src/Assets/images/main_bg.svg"


const Banner = () => {
    return (
        <Row className="home-banner main-wrapper flex align-center justify-center" style={{ backgroundImage: `url(${BackgroundMainSvg})`, backgroundSize: "cover" }}>
            <HeaderMenu /> {/* Is position fixed so it exists */}
            <Row className="flex align-center justify-center" style={{ maxWidth: '100%', paddingTop: "50px" }}>
                <Col className="home-banner-area" style={{ maxWidth: '850px', textAlign: 'center', padding: '50px 0' }}>
                    <p style={{ fontWeight: 700, color: 'var(--primary-color)' }}>
                        Document Review and Analysis Platform
                    </p>
                    <h2 className="banner-heading" style={{ fontWeight: 600 }}>
                        Transformative <span>AI Solution</span> for your Document Challenges
                    </h2>
                    <p className="banner-text">
                        Discover a new era of document review and analysis for your business. Powered by advanced knowledge graph technology and intelligent search capabilities, Proflow provides a seamless and insightful experience for managing your documents like never before.
                    </p>
                </Col>
            </Row>
        </Row>
    )
}

export default Banner