import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Home from "src/Pages/Home/Home"
import Solutions from "src/Pages/Solutions"
import Imprint from "src/Pages/Imprint/Imprint"
import Privacy from "src/Pages/Privacy/Privacy"

// Routes: React is a SPA (Single Page Application). Routing helps to navigate from one screen (or component) to another.

const AppRoutes = () => {

    const routes = [
        // GUEST GUARDS HERE
        {
            path: "/",
            element: Home,
        },
        {
            path: "/solutions",
            element: Solutions,
        },{
            path: "/imprint",
            element: Imprint,
        },{
            path: "/privacy",
            element: Privacy,
        }
    ]

    return (
        
        <Router>
            <Routes>
                {routes?.map((route, index) => {
                    const Guard = route?.guard || Fragment
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <Guard>
                                    <route.element />
                                </Guard>
                            }
                        />
                    )
                }
                )}
            </Routes>
        </Router>
    )
}

export default AppRoutes
