import React from 'react'
import { StarOutlined, StarFilled, StarTwoTone, DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const items = [
    {
        label: 'Platform',
        key: 'platform',
        icon: <DownOutlined />,
        children: [
            {
                label: "Sub menu",
                key: "submenu01"
            }
        ]
    },
    {
        label: 'Solutions',
        key: 'solutions',
        icon: <DownOutlined />,
        children: [
            {
                label: "Sub menu",
                key: "submenu02"
            }
        ]
    },
    {
        label: 'About',
        key: 'about',
    },
    {
        label: 'Contact Us',
        key: 'contactus',
    },
];


const CustomMenu = ({
    mode = "horizontal"
}) => {
    return (
        <Menu
            mode={mode}
            items={items}
            className='custom-menu'
        />
    )
}

export default CustomMenu
