import React from 'react'
import { Row, Col, Form , message, Spin} from 'antd'
import { useEffect ,useState} from 'react';
import './proflowform.css'
import CustomTextInput from '../../../Components/Common/CustomTextInput'
import CustomTextArea from '../../../Components/Common/CustomTextArea'
import CustomSubmitButton from '../../../Components/Common/CustomSubmitButton'
import { ReactComponent as ProflowIcon } from "../../../Assets/images/proflow_logo_white.svg"
import BackgroundSvg from "../../../Assets/images/background-wavy.svg"
import { MailOutlined, PhoneFilled, PhoneOutlined } from '@ant-design/icons'


const quickLinks2 = [
  {
    title: "Privacy Policy",    
    link: "#"
  },
  {
    title: "Imprint",
    link: "/imprint"
  },
]

const ProFlowForm = ({ ref1, ref2, ref3 }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const onFinish = async (values) => {
    setIsLoading(true); // Start loading
    try {
      const response = await fetch('http://localhost:8081/rest/api/sendCustomMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      console.log('Success:', data);

      message.success('Email sent successfully!'); 
      // Handle success response
    } catch (error) {
      message.error('Failed to send email. Please check your if your email address input text is ok'); // D
      console.error('Error:', error);
      // Handle error here
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: '',
      email: '',
      message: '',
    });
  }, []);

  const quickLinks = [
    {
      title: "Why Proflow",
      link: "#",
      onClick: () => ref2.current?.scrollIntoView({ behavior: 'smooth' })
    },
    {
      title: "How it Works",
      link: "#",
      onClick: () => ref1.current?.scrollIntoView({ behavior: 'smooth' })
    },
    {
      title: "More Features",
      link: "#",
      onClick: () => ref3.current?.scrollIntoView({ behavior: 'smooth' })
    },
  ]

  return (
    <div>
      <Spin spinning={isLoading} delay={500}> {/* Use Spin to indicate loading */}
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row className="form-area padding-all flex align-center justify-space-between main-wrapper" style={{position: "relative" , background: '#fff'}}>
          <Col xs={24} lg={12}>
            <div className='form-area-content' style={{ marginRight: "130px" }}>
              <h3
                className='title1'
                style={{ paddingBottom: '16px' }}
              >
                Ready to See Proflow<br /> in Action?
              </h3>
              <p className="body2">
                Do you want to know if Proflow is a fit for your business  or do you want to get more information from our experts? Simply get in touch!
              </p>
              <p className='body1' style={{ marginTop: "15px", fontWeight: 500 }}>
                Proflow GmbH
              </p>
              <p className='body1' style={{ marginTop: "5px" }}>
                <PhoneOutlined /> +43 664 158 5226
              </p>
              <p className='body1' style={{ marginTop: "5px" }}>
                <MailOutlined /> office@proflow.at
              </p>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={[15, 25]}>
              <Col xs={24} lg={12}>
                <CustomTextInput
                  label="Name"
                  name="firstName"
                />
              </Col>
              <Col xs={24} lg={12}>
                  <Form.Item name="email" rules={[
                      { required: true, message: 'Please input your email!' },
                      { type: 'email', message: 'Please enter a valid email address!' }
                  ]}
                  >
                    <CustomTextInput
                      label="Email"
                      name="email"
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <CustomTextArea
                  label="Message"
                  name="message"
                />
              </Col>
              <Col span={24}>
                <CustomSubmitButton
                  title='Submit'
                  type ='submit'
                  htmlType="submit"
                  minWidth='100%'
                  height='60px'
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      </Spin>
      <div className="footer-section main-wrapper" style={{ paddingTop: "150px", paddingBottom: "70px", display: "flex", color: "#fff", background: "#2C4794", backgroundImage: `url(${BackgroundSvg})`, backgroundSize: "cover" }}>
        <div style={{ marginRight: "8vw" }}>
          <ProflowIcon />
          <p
            className='body1'
            style={{ fontSize: "16px", color: "#fff", opacity: 0.7, marginTop: "10px" }}
          >
            © 2024 Proflow GesmbH
          </p>
        </div>
        <div className='links-wrapper' style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          <div className="footer-title" style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <p className="footer-heading" style={{ fontSize: "20px", fontWeight: 600 }}>Quick Links</p>
            <div className="footer-links" style={{ display: "flex" }}>
              {quickLinks.map(obj => (
                <p
                  className='body1'
                  style={{ marginRight: "16px", color: "#fff", opacity: 0.7, fontWeight: 300, cursor: "pointer" }}
                  onClick={obj.onClick}
                >
                  {obj.title}
                </p>
              ))}
            </div>
          </div>

          

          <div className='footer-links-2' style={{ display: "flex", alignSelf: "flex-end" }}>
          

            
              <p
                className='body1'
                style={{ marginRight: "20px", color: "#fff", opacity: 0.7, fontWeight: 300, cursor: "pointer" }}
              >
                 <a href="/Privacy" className='body1' style={{ marginRight: "20px", color: "#ffff", opacity: 1.0, fontWeight: 300, cursor: "pointer" ,"text-decoration": "none"}}>Privacy Policy</a>
                 <a href="/Imprint" className='body1' style={{ marginRight: "20px", color: "#fff", opacity: 1.0, fontWeight: 300, cursor: "pointer", "text-decoration": "none" }}>Imprint</a>
                
              </p>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProFlowForm
