import React from 'react';
import './Imprint.css'; // Ensure this file exists and has the styles referenced below
import HeaderMenu from '../../Containers/Home/HomeBanner/HeaderMenu/HeaderMenu';
import BackgroundMainSvg from "src/Assets/images/main_bg.svg";

const Imprint = () => {
    return (

        <div className="home-banner main-wrapper" style={{ backgroundColor: `#ECF1FF`, backgroundSize: "cover" }}>
            <HeaderMenu />

            <div className="imprint-container" style={{ backgroundColor: `#ECF1FF`, backgroundSize: "cover", height:"100vh" }}>
                

                <div className="imprint-content">
                    <h1 className="imprint-title">Imprint</h1>
                    <div className="imprint-details">
                        <p className="imprint-company-name"><strong>Proflow GmbH</strong></p>

                        <p className="imprint-address">Address: Ghegagasse 4, 2620 Neunkirchen, AT</p>
                        <p className="imprint-email">Email: office@proflow.at</p>
                        <p className="imprint-phone">Phone: +43 664 158 5226</p>
                        <p className="imprint-vat">VAT ID: ATU64005409</p>
                        <p className="imprint-register">Commercial Register: FN 306737 i</p>
                    </div>
                </div>
            </div>                
        </div>
    );
};

export default Imprint;
