// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:600px) {
    .what-we-do-main .container {
        padding-bottom: 60px !important;
    }

    .what-we-do-main .what-we-do-row {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 30px !important;
    }

    .what-we-do-main .content-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .what-we-do-main .content-wrapper .title1 {
        font-size: 22px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/WhatWeDo/whatwedo.css"],"names":[],"mappings":"AAAA;IACI;QACI,+BAA+B;IACnC;;IAEA;QACI,0BAA0B;QAC1B,2BAA2B;QAC3B,4BAA4B;IAChC;;IAEA;QACI,0BAA0B;QAC1B,2BAA2B;IAC/B;;IAEA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["@media only screen and (max-width:600px) {\n    .what-we-do-main .container {\n        padding-bottom: 60px !important;\n    }\n\n    .what-we-do-main .what-we-do-row {\n        padding-left: 0 !important;\n        padding-right: 0 !important;\n        padding-top: 30px !important;\n    }\n\n    .what-we-do-main .content-wrapper {\n        padding-left: 0 !important;\n        padding-right: 0 !important;\n    }\n\n    .what-we-do-main .content-wrapper .title1 {\n        font-size: 22px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
