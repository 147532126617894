// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-menu {
    height: 80px;
    width: 100%;
    transition: all 0.25s ease-out;
    background: #255279;
    border-bottom: 1px solid transparent;
}

.header-menu.scroll-active {
    background: #255279;
    border-bottom: 1px solid #eee;
}

.proflow-logo img {
    width: 247px;
    height: 60px;
    flex-shrink: 0;
}

@media only screen and (max-width: 600px) {
    .header-menu {
        padding: 0 15px;
        height: 60px;
    }

    .header-menu .navigation-menu {
        display: none;
    }

    .proflow-logo img {
        width: 80px;
        height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/HomeBanner/HeaderMenu/headermenu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;QACf,YAAY;IAChB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".header-menu {\n    height: 80px;\n    width: 100%;\n    transition: all 0.25s ease-out;\n    background: #255279;\n    border-bottom: 1px solid transparent;\n}\n\n.header-menu.scroll-active {\n    background: #255279;\n    border-bottom: 1px solid #eee;\n}\n\n.proflow-logo img {\n    width: 247px;\n    height: 60px;\n    flex-shrink: 0;\n}\n\n@media only screen and (max-width: 600px) {\n    .header-menu {\n        padding: 0 15px;\n        height: 60px;\n    }\n\n    .header-menu .navigation-menu {\n        display: none;\n    }\n\n    .proflow-logo img {\n        width: 80px;\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
