import React from 'react';
import { Row, Col, Form } from 'antd'
import { Table } from 'antd'; // Import Table
import './privacy.css'

import CustomTextInput from '../../Components/Common/CustomTextInput'
import CustomTextArea from '../../Components/Common//CustomTextArea'
import CustomButton from '../../Components/Common/CustomButton'
import { ReactComponent as ProflowIcon } from "../../Assets/images/proflow_logo_white.svg"
import BackgroundSvg from "../../Assets/images/background-wavy.svg"
import { MailOutlined, PhoneFilled, PhoneOutlined } from '@ant-design/icons'

//import HeaderMenu from '../../Containers/Home/Banner/HeaderMenu/HeaderMenu'
import BackgroundMainSvg from "src/Assets/images/main_bg.svg"

import HeaderMenu from '../../Containers/Home/HomeBanner/HeaderMenu/HeaderMenu'

const Privacy = () => {
    return (
        <div className="home-banner main-wrapper" style={{ backgroundColor: `#ECF1FF`, backgroundSize: "cover" }}>
            <HeaderMenu />

            <br></br>
            <br></br><br></br><br></br><br></br>
            <table border="0" style={{ margin: '0 auto', width: '100%', borderCollapse: 'collapse', backgroundColor: `#ECF1FF`, backgroundSize: "cover" }}>
                <tr>
                    <td height="100px" align="center"><h2>Data Protection</h2></td>
                </tr>

            </table>


            <div className="flex align-center justify-center" style={{ maxWidth: '100%', textAlign: 'center', }}>

                <table border="0" style={{ margin: '0 auto', width: '80%', borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td align="left" width="48%">

                                <p><strong>A. General Provisions</strong></p>
                                <p>With this data protection declaration, we inform you about how we use, pass on and&nbsp;otherwise process the personal data collected from you. We treat all personal data in&nbsp;accordance with the provisions of European and Austrian data protection law.</p>


                                <p><strong><br></br>1. Controller</strong></p>
                                <p>Proflow GmbH<br></br>Ghegagasse 4<br></br>2620 Neunkirchen<br></br>T: +43 664 158 5226<br></br>E: office@proflow.at</p>
                                <p><strong><br></br>2. Personal Data</strong><br></br></p>
                                <p>We collect, process and use your personal data only in accordance with Article 6 Paragraph&nbsp;1 GDPR or, where applicable, Article 9 GDPR. In principle, we only process your personal data if: </p>

                                <ul>
                                    <li> you have given your express consent in accordance with Article 6 Paragraph 1 lit. a&nbsp;GDPR, or</li>
                                    <li> this is necessary for the processing of contractual relationships with you (Art. 6 Para.&nbsp;1 lit. b GDPR), or</li>
                                    <li> the processing is necessary to fulfill one of our legal obligations (Art. 6 Para. 1 lit. c&nbsp;GDPR).</li>
                                </ul>

                                <p>The legal basis specifically applicable to the respective processing purpose can be found&nbsp;below in the explanations on the individual processing purposes.&nbsp;Personal data is in the meaning of the GDPR all data about natural persons whose identity&nbsp;is determined or at least determinable and which contains individual information about&nbsp;personal or factual circumstances, e.g. name, address, e-mail address, telephone number,&nbsp;date of birth, age, gender, social security number, proof of income, Video recordings,&nbsp;photos.<br></br><br></br></p>


                                <p><strong>3. Data subject rights</strong></p>
                                <p>As our contractual partner or generally as the person concerned, you have the right to&nbsp;information about your stored personal data as well as a right to correction, data transfer,&nbsp;objection, restriction of processing as well as blocking/anonymization or deletion of the&nbsp;data, insofar as there are no exceptions (e.g. statutory storage obligation). and in&nbsp;accordance with the statutory provisions.</p>

                                <p><br></br>If there are any changes to your personal data, we ask that you inform us&nbsp;accordingly. You have the right to revoke your consent to the use of your personal data&nbsp;at any time. Your request for information, deletion, correction, restriction, objection and/or&nbsp;data transfer can be sent to the e-mail address <a href="mailto:office@proflow.at" target="_blank" rel="noreferrer noopener">office@proflow.at</a> be judged.</p>



                                <p>If you are of the opinion that the processing of your personal data by us violates the&nbsp;applicable data protection law or your data protection rights have been violated in any&nbsp;other way, you have the option of complaining to the responsible supervisory authority. In&nbsp;Austria, the data protection authority is responsible for this (www.dsb.gv.at).<br></br><br></br><strong>4. Data Security</strong></p>




                                <p>Your personal data is protected by appropriate organizational and technical precautions.&nbsp;These precautions relate in particular to protection against unauthorized, illegal or&nbsp;accidental access, processing, loss, use and manipulation. Notwithstanding the efforts to&nbsp;maintain a consistently high level of due diligence, it cannot be ruled out that information&nbsp;that you provide to us via the Internet will be viewed and used by other people. Please note&nbsp;that we therefore assume no liability whatsoever for the disclosure of information due to&nbsp;errors in data transmission not caused by us and/or unauthorized access by third parties&nbsp;(e.g. hack attack on e-mail account or telephone, interception of faxes).</p>






                            </td>
                            <td align="left" width="4%"></td>
                            <td align="left" valign="top" width="48%">

                                <p><strong>5. Transmission of data to third parties</strong><br></br><br></br>In order to fulfill our contractual or legal obligations, the purpose of data processing or if there is a legitimate interest in the business transaction (third parties), it may also be necessary to transmit your data to third parties. Possible recipients can be: private and public bodies that disclose or require information in connection with the contractual relationship (e.g. subcontractors), insurance companies (e.g. in connection with claims), tax consultants, management consultants or lawyers, authorities, IT and telecommunications service providers. This forwarding of your data takes place exclusively on the basis of the GDPR.If the data is transmitted to third parties for individual processing purposes, where further information is required, you will find this under the respective purpose.As a rule, your data will be processed within the EEA. However, we only transfer your personal data outside of the EEA to countries for which the EU Commission has decided that they have an adequate level of data protection or we take measures to ensure that all recipients have an adequate level of data protection, for which we have Standard Contractual Clauses (2010 /87/EC and/or 2004/915/EC).<br></br><br></br></p>
                                <h4><strong>6. Data retention</strong></h4>
                                <p><strong></strong>In accordance with the applicable data protection requirements, we are obliged to delete&nbsp;personal data immediately in accordance with Article 5 Paragraph 1 lit. e GDPR as soon as&nbsp;the purpose for processing has been fulfilled. In this context, we would like to point out&nbsp;that legal storage obligations and periods represent a legitimate purpose for&nbsp;the processing of personal data.&nbsp;In any case, data will be stored and stored by us in personal form until the end of the&nbsp;business relationship or beyond for the retention periods of the BAO under tax law. Insofar&nbsp;as there are deviating results for individual processing purposes, this is stated for the&nbsp;respective purpose.<br></br><br></br></p>
                                <p><strong>B. Customer Data</strong><br></br>1st contact<br></br>If you contact us by e-mail, the following data will be processed by you:</p>
                                <ul><li>• news<br></br>• First and Last Name<br></br>• E-mail address<br></br>• phone number<br></br>• Date and Time<br></br>• Metadata of the e-mail (e.g. header)</li></ul>
                                <p>This data is processed to fulfill (pre-)contractual obligations in accordance with Art 6 Para&nbsp;1 lit b GDPR. We store the data until your inquiry has been answered, unless there is a legal&nbsp;obligation to retain it from the content of the respective inquiry.<br></br><strong><br></br>2. Customer Management</strong><br></br>We process the data of our customers for (pre-)contractual communication and for the&nbsp;processing and fulfillment of contracts (Art 6 Para 1 lit b GDPR). Specifically, this concerns&nbsp;the following data: company name, company register number/court, registered&nbsp;office/address, contact details, information on contact persons and their contact details,&nbsp;content of the order (service description, prices, payment terms, other contract&nbsp;components), correspondence within the (pre-) contractual communication.&nbsp;This data will be processed for the duration of the contract and for a further 7 years (tax&nbsp;retention obligation).</p>
                                <p></p>

                            </td>
                        </tr>
                        {/* Add more rows as needed */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Privacy;