import React from 'react'
import { Col, Row, Typography } from 'antd';
import './whatwedo.css'
import WhatWeDoCard from '../../../Components/Home/WhatWeDo/WhatWeDoCard';
import image1 from '../../../Assets/images/Group 1000001788.png'
import image2 from '../../../Assets/images/Group 1000001790.png'
import image3 from '../../../Assets/images/Group 1000001786.png'
import { ReactComponent as CircleLeft } from "src/Assets/images/circle_bg_left.svg"
import { ReactComponent as CircleRight } from "src/Assets/images/circle_bg_right.svg"


const totalData = {
  "cards": [
    {
      "id": 1,
      "title": "Find Hidden Connections",
      "imageURL": image1,
      "description": "Proflow is developed with knowledge graph technology that organizes data as interconnected nodes. Thus, creating a rich and contextual web of relationships between data elements. This interconnected structure allows for more comprehensive insights and better understanding of data patterns, enabling users to discover hidden connections and make more informed decisions. Additionally, knowledge graphs support semantic search, which enhances the platform's search capabilities by understanding the context and intent behind user queries, leading to more accurate and relevant search results. Furthermore, the dynamic nature of knowledge graphs allows them to evolve and adapt as new data is added, ensuring the platform remains relevant and up-to-date in an ever-changing data landscape.",
      "direction": "row",
      "column1": "18",
      "column2": "6"
    },
    {
      "id": 2,
      "title": "Link your Business Knowledge",
      "imageURL": image2,
      "description": "You want to connect your business knowledge to your data? Proflow enables the integration of domain-specific expertise into the data analysis process. By incorporating business knowledge, the platform gains a deeper understanding of the data's context, ensuring more accurate and relevant insights for informed decision-making. Moreover, the ability to map business concepts and relationships onto the knowledge graph empowers users to explore data from a business-centric perspective, facilitating better alignment with organizational goals and objectives.",
      "direction": "row-reverse",
      "column1": "16",
      "column2": "8"
    },
    {
      "id": 3,
      "title": "Know your Documents",
      "imageURL": image3,
      "description": "The combination of named entity recognition (NER) with knowledge graph technology is a powerful advantage as it enhances the platform's ability to identify and extract important entities such as names, dates, locations, and more from unstructured data. This facilitates faster and more accurate data processing, enabling users to focus on relevant information. By incorporating these recognized entities into the knowledge graph, the platform can create meaningful connections between data elements, enabling a comprehensive understanding of relationships and insights.",
      "direction": "row",
      "column1": "17",
      "column2": "7"
    }

  ]
};


const WhatWeDo = () => {

  return (
    <div className='main-wrapper what-we-do-main' style={{ background: 'var(--bg-color)', position: "relative" }}>
      <Row className="padding-top" style={{ flexDirection: 'column', alignItems: 'center' }}>
        <h2>What we do Different</h2>
        <p
          style={{ fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'var(--text-color', marginTop: '5px' }}
        >
          Cutting-edge Technology for Superior Results!
        </p>
      </Row>
      <Row className='container' style={{ paddingBottom: "120px" }}>
        {
          totalData.cards.map((data) => {
            return (
              <WhatWeDoCard
                key={data.id}
                title={data.title}
                imageUrl={data.imageURL}
                description={data.description}
                direction={data.direction}
                column1={data.column1}
                column2={data.column2}
              />
            )
          })
        }
      </Row>
      <CircleLeft style={{ position: "absolute", left: 0, top: 0 }} />
      <CircleRight style={{ position: "absolute", right: 0, bottom: 0 }} />
    </div>
  )
}

export default WhatWeDo