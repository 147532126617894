import React, { useEffect, useState } from 'react'
import './headermenu.css'
import { Row, Col, Menu } from 'antd'
import { Link } from 'react-router-dom';
import ProFlow from '../../../../Assets/images/NeuProflowLogo.jpg'
//import ProFlow from '../../../../Assets/images/proflow_logo.svg'
import CustomMenu from 'src/Components/Common/CustomMenu';
import CustomDrawer from 'src/Components/Common/CustomDrawer';


const HeaderMenu = () => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.scrollY);
    };
  }, []);

  return (
    <Row className={offset > 100 ? "header-menu scroll-active" : "header-menu"} style={{ position: "fixed", left: 0, top: 0, padding: "inherit", zIndex: 99 }}>
      <Col span={24} className="flex align-center justify-space-between">
        <div className="proflow-logo">
          <Link to="/">
              <img src={ProFlow} alt="Proflow logo"  />
            </Link>
        </div>
        <div className="navigation-menu" style={{ flex: 1 }}>
          {/* <CustomMenu /> */}
        </div>
        <div className="search-area" style={{ display: "flex", alignItems: "center" }}>
          {/* <CustomDrawer />
          <button className="btn-learn" style={{ margin: 0, marginLeft: "10px", cursor: "pointer", fontWeight: 700 }}>
            Request Demo
          </button> */}
        </div>
      </Col>
    </Row>
  )
}

export default HeaderMenu
