// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:600px) {
    .about-us, .about-area {
        margin-top:30px !important;   
    }
    .grid-2 {
        grid-template-columns: repeat(1,1fr) !important;
    }

    .aboutCardImage {
        border-radius: 15px; /* Adjust the radius as needed */
      }
}

`, "",{"version":3,"sources":["webpack://./src/Containers/Home/AboutUs/aboutus.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,+CAA+C;IACnD;;IAEA;QACI,mBAAmB,EAAE,gCAAgC;MACvD;AACN","sourcesContent":["@media only screen and (max-width:600px) {\n    .about-us, .about-area {\n        margin-top:30px !important;   \n    }\n    .grid-2 {\n        grid-template-columns: repeat(1,1fr) !important;\n    }\n\n    .aboutCardImage {\n        border-radius: 15px; /* Adjust the radius as needed */\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
