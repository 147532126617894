import React from 'react'
import AboutCard from '../../../Components/Home/AboutUs/AboutCard'
import './aboutus.css'
import { Col, Row, Typography } from 'antd';
import image1 from '../../../Assets/images/Rudolf1.jfif'
import image2 from '../../../Assets/images/Adriane1.jfif'

const aboutData = {
  "cards": [
    {
      "id": 1,
      "position": "CEO & Founder",
      "title": "Rudolf Röckelein",
      "imageURL": image1
    },
    {
      "id": 2,
      "position": "Managing Director",
      "title": "Adriane Röckelein",
      "imageURL": image2
    }

  ]
};

const AboutUs = () => {
  return (
    <div className='main-wrapper' style={{position: "relative" , background: '#fff'}}>
      <Row className="padding-top" style={{ background: 'var(--color-white)', justifyContent: 'center', alignItems: 'center' }}>
        <h2>About Us</h2>
      </Row>
      <Row gutter={32} className="padding-bottom flex align-center justify-center about-us" style={{ background: 'var(--color-white', marginTop: '56px' }}>
        <Col xs={24} lg={12}>
          <h3
            className='title1 h3-small'
            style={{ paddingBottom: '16px' }}
          >
            Meet the Management Team
          </h3>
          <p className="body2">
            In today's fast-paced world, businesses are overwhelmed with a flood of documents. Our mission is to tackle this challenge by providing an innovative platform that revolutionizes the way data is comprehended. With a dedication to great user experience and continuous improvement, we want to be a catalyst that empowers businesses to thrive and undergo transformative growth.
          </p>
        </Col>
        <Col className="grid-2 about-area" xs={24} lg={12}>
          {
            aboutData.cards.map((card) => {
              return (
                <AboutCard key={card.key} position={card.position} title={card.title} image={card.imageURL} imageStyle={{ borderRadius: '15px' }} />
              )
            })
          }
        </Col>
      </Row>
    </div>
  )
}

export default AboutUs