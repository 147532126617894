import React, { useRef } from 'react'
import { Row, Col, Rate, Carousel } from 'antd'
import nextReview from '../../../Assets/Icons/next-review.png'
import previousReview from '../../../Assets/Icons/previous-review.png'
import './testimonials.css'
import Armin from 'src/Assets/images/Armin-Vogl.jfif'
import Pilles from 'src/Assets/images/HC-Pilles.jfif'
import TestimonialsBg from "src/Assets/images/testimonials_bg.svg"


const data = [
  {
    img: Armin,
    name: "Armin Vogl",
    position: "Migration Analyst, MoI Austria",
    desc: "The accuracy and speed at which this tool processes data and documents is unparalleled. A vital asset for any organization dealing with large volumes of information. Also, building your own dashboards is highly intuitive and flexible."
  },
  {
    img: Pilles,
    name: "Hans Christian Pilles",
    position: "BMLV",
    desc: "It is a “simple to use” but powerful tool to collate, search, tag and find “quick and easy” text passages in a huge amount of documents. A wonderful tool for working with a big amount of information, providing a comprehensive overview, and arranging them in the desired order."
  }
]

const Testimonials = () => {

  const ref = useRef()

  return (
    <div className="padding-all align-center justify-center main-wrapper" style={{ background: 'var(--bg-color)' }}>
      <div>
        <h3
          style={{ paddingBottom: '16px', textAlign: "center" }}
          className='title1'
        >
          Reviews & Testimonials
        </h3>
        <Row className="flex align-center justify-end" style={{ minWidth: '100%' }}>
          <Col className="flex align-center justify-end">
            <img style={{ width: '26px', height: '26px', cursor: "pointer" }} src={previousReview} alt="previous review" onClick={() => ref.current.prev()} />
            <img style={{ width: '40px', height: '40px', marginLeft: '24px', cursor: "pointer" }} src={nextReview} alt="next review" onClick={() => ref.current.next()} />
          </Col>
        </Row>
      </div>
      <Carousel
        dots={false}
        autoplay={true}
        autoplaySpeed={10000}
        ref={ref}
      >
        {data.map(obj => (
          <Row style={{ minWidth: '100%' }}>
            <Row className="testimonial-card" style={{ marginTop: '24px' }}>
              <Col style={{ background: 'var(--primary-color)', borderRadius: '16px 0 0 16px' }} xs={24} lg={6} className="testimonial-profile flex align-center justify-center">
                <img style={{ width: '126px', height: '126px', objectFit: "cover", borderRadius: "50%" }} src={obj.img} alt={obj.name} />
              </Col>
              <Col span={18} className="testimonial-content" style={{ backgroundImage: `url(${TestimonialsBg})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundOrigin: "content-box" }}>
                <h4 className="author-title">
                  {obj.name}
                </h4>
                <p className="author-position">
                  {obj.position}
                </p>
                <p className="author-review">
                  {obj.desc}
                </p>
                <Rate style={{ color: '#EBA859' }} disabled defaultValue={5} />
              </Col>
            </Row>
          </Row>
        ))}
      </Carousel>
    </div>
  )
}

export default Testimonials
