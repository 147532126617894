import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import CustomMenu from "./CustomMenu";

const CustomDrawer = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <div className="custom-drawer">
            <MenuOutlined
                onClick={showDrawer}
                style={{
                    fontSize: 20
                }}
            />
            <Drawer title="Menu" placement="right" onClose={onClose} open={open}>
                <CustomMenu
                    mode="inline"
                />
            </Drawer>
        </div>
    );
};

export default CustomDrawer