import React from 'react'
import './featurebox.css'

const FeatureBox = ({ title, description, image }) => {
  return (
    <>
      <img src={image} alt="feature icon" />
      <h5 className="feature-box-heading">
        {title}
      </h5>
      <p className="body1">
        {description}
      </p>
    </>
  )
}

export default FeatureBox