// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media only screen and (max-width:600px) {

    .form-area h3,
    .form-area .p-18 {
        justify-content: start;
        /* padding: 0 50px; */
    }

    .form-area .p-18 {
        margin-bottom: 30px;
    }

    .footer-section {
        display: block !important;
    }

    .footer-heading {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .form-area .form-area-content {
        margin-right: 0 !important;
        margin-bottom: 30px !important;
    }

    .links-wrapper {
        flex-direction: column;
    }

    .footer-links {
        flex-direction: column;
    }

    .footer-links-2 {
        align-self: flex-start !important;
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/ProFlowForm/proflowform.css"],"names":[],"mappings":";;AAEA;;IAEI;;QAEI,sBAAsB;QACtB,qBAAqB;IACzB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,yBAAyB;IAC7B;;IAEA;QACI,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,0BAA0B;QAC1B,8BAA8B;IAClC;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,iCAAiC;QACjC,sBAAsB;IAC1B;AACJ","sourcesContent":["\n\n@media only screen and (max-width:600px) {\n\n    .form-area h3,\n    .form-area .p-18 {\n        justify-content: start;\n        /* padding: 0 50px; */\n    }\n\n    .form-area .p-18 {\n        margin-bottom: 30px;\n    }\n\n    .footer-section {\n        display: block !important;\n    }\n\n    .footer-heading {\n        margin-top: 20px;\n        margin-bottom: 10px;\n    }\n\n    .form-area .form-area-content {\n        margin-right: 0 !important;\n        margin-bottom: 30px !important;\n    }\n\n    .links-wrapper {\n        flex-direction: column;\n    }\n\n    .footer-links {\n        flex-direction: column;\n    }\n\n    .footer-links-2 {\n        align-self: flex-start !important;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
