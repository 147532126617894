import React from 'react'
import './proflowcard.css'

const ProFlowCard = ({ obj, title, description }) => {
    return (
        <div className="proflow-card" style={{ padding: '30px 20px', height: "100%" }}>
            <div
                className="proflow-card-header"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <h5
                    style={{ color: '#2D2D2D', fontSize: '20px', lineHeight: '28px', fontWeight: 700, marginRight: "5px" }}
                >
                    {title}
                </h5>
                {<obj.imageURL />}
            </div>
            <div className="proflow-card-content">
                <p
                    style={{
                        marginTop: '22px'
                    }}
                    className='body1'
                >
                    {description}
                </p>
            </div>
        </div>
    )
}

export default ProFlowCard