// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-position {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
}
.about-card {
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
    background:var(--bg-color);
}
.card-title {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    padding-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Home/AboutUs/AboutCard.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAE,WAAW;AAClC;AACA;IACI,mBAAmB;IACnB,oFAAoF;IACpF,0BAA0B;AAC9B;AACA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAE,WAAW;IAC9B,mBAAmB;AACvB","sourcesContent":[".card-position {\n    color: var(--primary-color);\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 120%; /* 16.8px */\n}\n.about-card {\n    border-radius: 16px;\n    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);\n    background:var(--bg-color);\n}\n.card-title {\n    color: var(--text-color);\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 120%; /* 28.8px */\n    padding-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
