// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-banner {
    background: var(--bg-color);
    height: calc(100vh - 165px);
}

.banner-heading {
    color: var(--text-color, #2D2D2D);
    font-size: 56px;
    font-weight: 700;
    line-height: 120%;
    padding: 16px 0;
}

.banner-heading span {
    color: var(--primary-color, #2C4794);
}

.banner-text {
    color: var(--text-color, #2D2D2D);
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
}

@media only screen and (max-width:600px) {
    .home-banner {
        height: auto;
    }

    .home-banner h2 {
        font-size: 26px;
    }

    .banner-text {
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/HomeBanner/Banner/Banner.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".home-banner {\n    background: var(--bg-color);\n    height: calc(100vh - 165px);\n}\n\n.banner-heading {\n    color: var(--text-color, #2D2D2D);\n    font-size: 56px;\n    font-weight: 700;\n    line-height: 120%;\n    padding: 16px 0;\n}\n\n.banner-heading span {\n    color: var(--primary-color, #2C4794);\n}\n\n.banner-text {\n    color: var(--text-color, #2D2D2D);\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 150%;\n}\n\n@media only screen and (max-width:600px) {\n    .home-banner {\n        height: auto;\n    }\n\n    .home-banner h2 {\n        font-size: 26px;\n    }\n\n    .banner-text {\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
