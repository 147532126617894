// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box {
    width:210px;
    height: 101px;
    background: var(--white-color);
    border-radius: 8px;
    margin-right:48px;
    border: 1px solid rgba(243, 243, 254, 0.16);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}
.search-box-text {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 700;
    line-height: 120%; /* 26.4px */
}

@media only screen and (max-width:600px) {
    .search-box {
        width:100%;
        margin-bottom: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/SearchBox/searchbox.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;IACjB,2CAA2C;IAC3C,yHAAyH;AAC7H;AACA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAE,WAAW;AAClC;;AAEA;IACI;QACI,UAAU;QACV,mBAAmB;IACvB;AACJ","sourcesContent":[".search-box {\n    width:210px;\n    height: 101px;\n    background: var(--white-color);\n    border-radius: 8px;\n    margin-right:48px;\n    border: 1px solid rgba(243, 243, 254, 0.16);\n    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);\n}\n.search-box-text {\n    color: var(--primary-color);\n    font-size: 22px;\n    font-weight: 700;\n    line-height: 120%; /* 26.4px */\n}\n\n@media only screen and (max-width:600px) {\n    .search-box {\n        width:100%;\n        margin-bottom: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
