import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import './_CustomTextInput.css'

const CustomTextInput = ({
    label,
    name,
    placeholder = "Type here",
    rules,
    padding = "0 12px 5px 12px",
    fontSize = "14px",
    color = "#2D2D2D",
    background = "#FFF",
    borderRadius = "0",
    styleObj,
    wrapperStyleObj,
    handleSearch = () => null,
    handleKeyDown = () => null,
    onChange = () => null,
    initialValue,
    type,
    required = false, // Mentioned explicitly because antd adds "*" when required true
    isDebounce = false,
    ...rest
}) => {
    const [focusedInput, setFocusedInput] = useState(null)

    return (
        <Form.Item
            label={label && (
                <p style={{ fontWeight: 600, fontSize: "16px", color: "#2C4794" }}>{label}</p>
            )}
            name={name}
            rules={rules}
            required={required}
            initialValue={initialValue}
            className='custom-text-input'
            style={{
                marginBottom: 0,
                padding: 0,
                ...wrapperStyleObj
            }}
        >
            <Input
                bordered={false}
                placeholder={placeholder}
                onChange={onChange}
                type={type}
                onFocus={() => setFocusedInput(name)}
                onBlur={() => setFocusedInput(null)}
                style={{
                    background: background,
                    padding: padding,
                    fontSize: fontSize,
                    borderRadius: borderRadius,
                    color: color,
                    borderBottom: focusedInput ? "1px solid #2C4794" : "1px solid #ddd",
                    ...styleObj
                }}
                {...rest}
            />
        </Form.Item>
    )
}

export default CustomTextInput
