import React from 'react'
import { Col, Row, Typography } from 'antd';
import './whatwedocard.css'

const WhatWeDoCard = ({ key, title, imageUrl, description, direction, column1, column2 }) => {
  return (
    <Row className="what-we-do-row" key={key} style={{ padding: 0, paddingTop: '80px', alignItems: 'center', flexDirection: `${direction}`, paddingRight: direction === "row" ? "70px" : 0, paddingLeft: direction === "row-reverse" ? "70px" : 0 }} gutter={30}>
      <Col className="what-we-do-col" span={column1}>
        <div className='content-wrapper' style={{ paddingRight: direction === "row" ? "120px" : 0, paddingLeft: direction === "row-reverse" ? "120px" : 0 }}>
          <h3 className='title1' style={{ paddingBottom: '24px' }}>
            {title}
          </h3>
          <p className='body2'>
            {description}
          </p>
        </div>
      </Col>
      <Col className="what-we-do-col" span={column2}>
        <img src={imageUrl} alt="person holding a laptop" style={{ maxWidth: '100%' }} />
      </Col>
    </Row>
  )
}

export default WhatWeDoCard