import React from 'react'
import './searchbox.css'

const SearchBox = ({title}) => {
  return (
    <div className="search-box flex align-center justify-center">
        <p class="search-box-text">
            {title}
        </p>
    </div>
  )
}

export default SearchBox