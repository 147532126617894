// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    background-color: #ECF1FF;
}

.proflow-logo img {
    width: 247px;
    height: 60px;
    position: relative;
    
    flex-shrink: 0;
}

p, ul {
    font-size: 16px;
    line-height: 1.5;
}

ul {
    margin-left: 40px; /* Adjust list indentation */
}`, "",{"version":3,"sources":["webpack://./src/Pages/Privacy/privacy.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;;IAElB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB,EAAE,4BAA4B;AACnD","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    background-color: #ECF1FF;\n}\n\n.proflow-logo img {\n    width: 247px;\n    height: 60px;\n    position: relative;\n    \n    flex-shrink: 0;\n}\n\np, ul {\n    font-size: 16px;\n    line-height: 1.5;\n}\n\nul {\n    margin-left: 40px; /* Adjust list indentation */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
