// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-learn {
    border: none;
    background: var(--primary-color);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 23px;
    border-radius: 25px;
    margin-top: 24px;
}

@media only screen and (max-width:600px) {
    .btn-learn {
        padding: 7px 15px;
        font-size: 12px;
    }

    .more-features-col .grid-3 {
        display: block;
    }

    .more-features {
        padding-top: 32px;
    }

    .more-features-heading {
        text-align: left;
        padding-bottom: 50px;
    }

    .more-features-heading h3 {
        justify-content: start;
    }

    .single-feature-box {
        padding-bottom: 40px;
    }

    .more-features .state-of-the-art-features{
        padding-right: 0 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/MoreFeatures/morefeatures.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gCAAgC;IAChC,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;QACjB,eAAe;IACnB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,gBAAgB;QAChB,oBAAoB;IACxB;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,oBAAoB;IACxB;;IAEA;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".btn-learn {\n    border: none;\n    background: var(--primary-color);\n    color: #fff;\n    font-size: 16px;\n    font-weight: 600;\n    padding: 10px 23px;\n    border-radius: 25px;\n    margin-top: 24px;\n}\n\n@media only screen and (max-width:600px) {\n    .btn-learn {\n        padding: 7px 15px;\n        font-size: 12px;\n    }\n\n    .more-features-col .grid-3 {\n        display: block;\n    }\n\n    .more-features {\n        padding-top: 32px;\n    }\n\n    .more-features-heading {\n        text-align: left;\n        padding-bottom: 50px;\n    }\n\n    .more-features-heading h3 {\n        justify-content: start;\n    }\n\n    .single-feature-box {\n        padding-bottom: 40px;\n    }\n\n    .more-features .state-of-the-art-features{\n        padding-right: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
