import React, { forwardRef } from 'react'
import { Col, Row, Typography } from 'antd';
import './cta.css'
import Paragraph from 'antd/es/skeleton/Paragraph';
import CustomButton from 'src/Components/Common/CustomButton';

const CTA = ({ref1}) => {
  return (
    <>
      <Row
        className="cta main-wrapper"
        style={{
          background: '#2C4794',
          minHeight: '165px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Col xs={24} lg={18} style={{ color: '#fff' }}>
          <p style={{ fontWeight: 700, fontSize: "24px" }}>
            Unified Platform
          </p>
          <p className="p-12" style={{ fontWeight: 300, marginTop: '5px', fontSize: "22px" }}>
            Are you struggling to keep your documents organized and easily retrievable?
          </p>
          <p className="p-12" style={{ fontWeight: 300, fontSize: "22px", marginTop: "2px" }}>
            We make your day-to-day easier and more efficient!
          </p>
        </Col>
        <Col xs={24} lg={6} className='button-wrapper' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton
            title='See For Yourself'
            minWidth='220px'
            height='60px'
            color='#2C4794'
            background='#fff'
            fontSize='20px'
            onClick={() => ref1.current?.scrollIntoView({ behavior: 'smooth' })}
          />
        </Col>
      </Row>
    </>
  )
}

export default CTA
