import { Form, Input } from 'antd'
import React, { useState } from 'react'

const { TextArea } = Input

const CustomTextArea = ({
    label,
    name,
    placeholder = "Type here",
    rules,
    padding = "0 12px 5px 12px",
    fontSize = "14px",
    color = "#2D2D2D",
    background = "#FFF",
    rows = 4,
    styleObj = {},
    wrapperStyleObj = {},
    borderRadius = "4px",
    required = false, // Mentioned explicitly because antd adds "*" when required true
    labelStyleObj,
    ...rest
}) => {
    const [focusedInput, setFocusedInput] = useState(null)

    return (
        <Form.Item
            label={label && (
                <p style={{ fontWeight: 600, fontSize: "16px", color: "#2C4794" }}>{label}</p>
            )}
            name={name}
            rules={rules}
            required={required}
            className='custom-text-area'
            style={{
                marginBottom: 0,
                ...wrapperStyleObj
            }}
        >
            <TextArea
                bordered={false}
                placeholder={placeholder}
                rows={rows}
                onFocus={() => setFocusedInput(name)}
                onBlur={() => setFocusedInput(null)}
                style={{
                    padding: padding,
                    fontSize: fontSize,
                    color: color,
                    background: background,
                    borderRadius: borderRadius,
                    borderBottom: focusedInput ? "1px solid #2C4794" : "1px solid #ddd",
                    ...styleObj
                }}
                {...rest}
            />
        </Form.Item>
    )
}

export default CustomTextArea
