import React, { forwardRef } from 'react'
import ProFlowCard from '../../../Components/Home/ProFlowCard/ProFlowCard'
import { Col, Row, Typography } from 'antd';
import { ReactComponent as HowItWorksIcon } from "../../../Assets/images/how_it_works.svg"
import { ReactComponent as ProflowBg } from "../../../Assets/images/proflow_bg.svg"
import { ReactComponent as Icon1 } from "src/Assets/Icons/icon1.svg"
import { ReactComponent as Icon2 } from "src/Assets/Icons/icon2.svg"
import { ReactComponent as Icon3 } from "src/Assets/Icons/icon3.svg"
import { ReactComponent as Icon4 } from "src/Assets/Icons/icon4.svg"
import { ReactComponent as Icon5 } from "src/Assets/Icons/icon5.svg"
import { ReactComponent as Icon6 } from "src/Assets/Icons/icon6.svg"
import { ReactComponent as Icon7 } from "src/Assets/Icons/icon7.svg"
import { ReactComponent as Icon8 } from "src/Assets/Icons/icon8.svg"
import "./whyproflow.css"

// Store the JSON data in a variable called "totalcardata"
const totalcardata = {
  "cards": [
    {
      "id": 1,
      "title": "Smart Document Analysis",
      "imageURL": Icon1,
      "description": "The platform can extract key insights, recognize patterns, and understand the context from diverse sources intelligently through cutting-edge AI and natural language processing algorithms. It delivers precise and relevant results empowering its users."
    },
    {
      "id": 2,
      "title": "Fast & Flexible",
      "imageURL": Icon2,
      "description": "With fast and secure uploading, your documents can be made searchable within seconds. Use pre-installed dashboards or simply create your own dashboards for any specific needs."
    },
    {
      "id": 3,
      "title": "Easy To Use Anytime",
      "imageURL": Icon3,
      "description": "Due to Proflows' intuitive user interface with simplified navigation and user-friendly features, users can quickly grasp its functionalities and efficiently perform document review tasks with a minimal learning curve."
    },
    {
      "id": 4,
      "title": "Better Knowledge Management",
      "imageURL": Icon4,
      "description": "Vast amounts of documents are centralized, organized, and categorized. Information is easily accessible and searchable and it enables users to extract valuable knowledge from the data, facilitating better decision-making."
    },
    {
      "id": 5,
      "title": "Cost Efficient & Effective",
      "imageURL": Icon5,
      "description": "Reducing the need for manual document handling and data entry, minimizing human errors, and optimizing review processes translates into lower operational expenses. Advanced analytical capabilities lead to improved resource allocation."
    },
    {
      "id": 6,
      "title": "Increase in Productivity",
      "imageURL": Icon6,
      "description": "With faster access to documents and its data, efficient data review processes, and data-driven decision-making, users can focus on high-value tasks, make informed decisions, and achieve more in less time."
    },
    {
      "id": 7,
      "title": "Reliable Data Security",
      "imageURL": Icon7,
      "description": "Our utmost priority lies in ensuring the highest level of data security for your review process. To achieve this, we have implemented a cloud-based closed-loop platform that safeguards all your valuable information."
    },
    {
      "id": 8,
      "title": "Unlocking your Scalability",
      "imageURL": Icon8,
      "description": "The platform can efficiently handle large volumes of documents. Its architecture and cloud-based infrastructure enables seamless expansion, making it well-suited for organizations of all sizes and adaptable to evolving data requirements."
    },
    // Add more cards as needed
  ]
};

const WhyProFlow = ({ ref1, ref2 }) => {
  return (
    <div className='why-proflow-main' ref={ref2} >
      <div className='main-wrapper' style={{background: '#fff'}}>
        <Row className="padding-top">
          <h3 className='title1'>
            Why Proflow?
          </h3>
        </Row>
        <Row className="padding-bottom" gutter={[20, 20]}>
          {totalcardata.cards.map((card) => (
            <Col xs={24} lg={6}>
              <ProFlowCard
                key={card.id}
                obj={card}
                title={card.title}
                icon={card.imageURL}
                description={card.description}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Row className='how-it-works' style={{position: "relative" , background: '#fff'}}>
        <h3 className='main-wrapper title1'>
          How does it Work?
        </h3>
        <HowItWorksIcon style={{ marginTop: "10px", marginBottom: "70px" }} />
        <ProflowBg style={{ position: "absolute", right: 0 }} />
        <div ref={ref1} style={{ position: "absolute", top: -100 }}></div>
      </Row>
    </div>
  )
}

export default WhyProFlow
