// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-experience-section {
    background: var(--primary-color);
    min-height: 165px;
    margin-bottom: 130px;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .search-experience-section {
        padding-top:32px !important;
        padding-bottom: 32px !important;
        margin-bottom: 0px !important;
    }
    .search-experience-p {
        font-size:18px;
        text-align: center;
        line-height: 26px;
    }
    .search-box-area {
        margin-bottom: 0px !important;
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Home/SearchExperience/searchexperience.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI;QACI,2BAA2B;QAC3B,+BAA+B;QAC/B,6BAA6B;IACjC;IACA;QACI,cAAc;QACd,kBAAkB;QAClB,iBAAiB;IACrB;IACA;QACI,6BAA6B;QAC7B,aAAa;QACb,oCAAoC;IACxC;AACJ","sourcesContent":[".search-experience-section {\n    background: var(--primary-color);\n    min-height: 165px;\n    margin-bottom: 130px;\n    position: relative;\n}\n\n@media only screen and (max-width: 600px) {\n    .search-experience-section {\n        padding-top:32px !important;\n        padding-bottom: 32px !important;\n        margin-bottom: 0px !important;\n    }\n    .search-experience-p {\n        font-size:18px;\n        text-align: center;\n        line-height: 26px;\n    }\n    .search-box-area {\n        margin-bottom: 0px !important;\n        display: grid;\n        grid-template-columns: repeat(1,1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
