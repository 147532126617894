import React from 'react'
import { Col, Row, Typography } from 'antd';
import './morefeatures.css'
import FeatureBox from '../../../Components/Home/FeaturesBox/FeatureBox';
import FeatureIcon from '../../../Assets/Icons/our-features.png'
import { ReactComponent as DottedBg } from "src/Assets/images/dotted_bg.svg"

const featureData = {
    "cards": [
        {
            "id": 1,
            "title": "Data Extraction",
            "description": "Proflow instantly identifies crucial entities from your documents, such as names, locations, organizations, and more.",
            "imageURL": FeatureIcon
        },
        {
            "id": 2,
            "title": "Terminology",
            "description": "Simplify your review process with built-in terminology support. This feature enables users to extract relevant information from their data.",
            "imageURL": FeatureIcon
        },
        {
            "id": 3,
            "title": "Individual Dashboards",
            "description": "Customize dashboards to your specific business needs with just a few clicks.",
            "imageURL": FeatureIcon
        },
        {
            "id": 4,
            "title": "OCR Technology",
            "description": "Don’t worry about scanned images or printed documents. Our OCR technology makes them searchable.",
            "imageURL": FeatureIcon
        },
        {
            "id": 5,
            "title": "Collaboration",
            "description": "Collaborate easily among team members by enabling individuals to access, review, and comment on documents simultaneously.",
            "imageURL": FeatureIcon
        },
        {
            "id": 6,
            "title": "History",
            "description": "You don’t remember your last search query? Our history feature saves all data about the things you do across Proflow.",
            "imageURL": FeatureIcon
        }

    ]
};

const MoreFeatures = ({ ref3 }) => {
    return (
        <Row className="more-features padding-bottom flex align-center main-wrapper" style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: -140 }} ref={ref3}></div>
            <Col className="more-features-heading" xs={24} lg={8}>
                <h3
                    className='title1'
                    style={{ paddingBottom: '16px' }}
                >
                    More Features
                </h3>
                <p className="body2 state-of-the-art-features" style={{ paddingRight: "100px" }}>
                    Check out more state-of-the-art features for superior results.
                </p>
                <button className="btn-learn">
                    Learn More
                </button>
            </Col>
            <Col className="more-features-col" xs={24} lg={16}>
                <Row className="grid-3 align-start justify-center">
                    {
                        featureData.cards.map((card) => {
                            return (
                                <Col className="single-feature-box">
                                    <FeatureBox
                                        id={card.id}
                                        title={card.title}
                                        description={card.description}
                                        image={card.imageURL}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            <DottedBg style={{ position: "absolute", bottom: "65%", left: 40 }} />
        </Row>
    )
}

export default MoreFeatures