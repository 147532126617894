// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:600px) {
    .what-we-do-col {
        min-width: 100%;
    }
    .what-do-do-row {
        display: block;
    }
    .what-do-do-row {
        padding-top: 30px !important;
    }
    .what-do-do-row h3 {
        padding-bottom: 12px !important;
        line-height: 32px !important;
        justify-content: start;
    }
    .what-we-do-col img {
        margin-top:40px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/WhatWeDo/whatwedocard.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;IACnB;IACA;QACI,cAAc;IAClB;IACA;QACI,4BAA4B;IAChC;IACA;QACI,+BAA+B;QAC/B,4BAA4B;QAC5B,sBAAsB;IAC1B;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":["@media only screen and (max-width:600px) {\n    .what-we-do-col {\n        min-width: 100%;\n    }\n    .what-do-do-row {\n        display: block;\n    }\n    .what-do-do-row {\n        padding-top: 30px !important;\n    }\n    .what-do-do-row h3 {\n        padding-bottom: 12px !important;\n        line-height: 32px !important;\n        justify-content: start;\n    }\n    .what-we-do-col img {\n        margin-top:40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
