import './App.css';
// import './fonts/AvenirLTProLight.otf';
// import './fonts/AvenirLTProBook.otf';
// import './fonts/AvenirLTProMedium.otf';
// import './fonts/AvenirLTProHeavy.otf';
import AppRoutes from './Routes';
import CookieConsent, { Cookies } from 'react-cookie-consent';

function App() {
  return (
    <div className="App">

<CookieConsent
        location="bottom"
        buttonText="I accept"
        declineButtonText="I decline"
        cookieName="userConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          // Initialize or enable scripts that use cookies here
        }}
        onDecline={() => {
          // Disable or don't initialize scripts that use cookies
        }}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>You can learn more about our cookie policy <a href="/privacy">here</a>.</span>
      </CookieConsent>

      <AppRoutes />
    </div>
  );
}

export default App;
