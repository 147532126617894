import React from 'react'
import './searchexperience.css'
import { Col, Row, Typography } from 'antd';
import SearchBox from '../../../Components/Home/SearchBox/SearchBox';
import { ReactComponent as CircleTop } from "src/Assets/images/circle_bg_top.svg"

const SearchExperience = () => {
  return (
    <div className='main-wrapper search-experience-section flex align-center justify-center flex-column'>
      <p
        className="search-experience-p"
        style={{ fontWeight: '700', color: '#fff' }}
      >
        Your Search Experience will also include:
      </p>
      <Row className="search-box-area" style={{ marginTop: '30px', marginBottom: '-60px', width: '100%', padding: 0, justifyContent: "center" }}>
        <SearchBox title={'Full-text Search'} />
        <SearchBox title={'Fuzzy Search'} />
        <SearchBox title={'Synonym Search'} />
        <SearchBox title={'Meta Search '} />
      </Row>
      <CircleTop style={{ position: "absolute", top: 0, left: 10 }} />
    </div>
  )
}

export default SearchExperience